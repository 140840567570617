.videoCenter .container[data-v-2573498e] {
  margin: 1.2rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.videoCenter .container[data-v-2573498e] .videoCenter-left .el-radio-group {
  margin-right: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.videoCenter .container[data-v-2573498e] .videoCenter-left .el-radio-group .el-radio.el-radio--large .el-radio__label {
  color: #000000;
  font-size: var(--f18);
  display: block;
  width: 100%;
}
.videoCenter .container[data-v-2573498e] .videoCenter-left .el-radio-group .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--comthemeColor);
  background: var(--comthemeColor);
}
.videoCenter .container[data-v-2573498e] .videoCenter-left .el-radio-group .el-radio__input.is-checked + .el-radio__label {
  color: var(--comthemeColor);
}
.videoCenter .container .videoCenter-right[data-v-2573498e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.videoCenter .container .videoCenter-right .video-item[data-v-2573498e] {
  width: 30%;
  margin-bottom: 0.62rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 0.32rem;
}
.videoCenter .container .videoCenter-right .video-item[data-v-2573498e]:nth-child(3n) {
  margin-right: 0;
}
.videoCenter .container .videoCenter-right .video-item .video[data-v-2573498e] {
  width: 100%;
  height: 2rem;
  -o-object-fit: fill;
  object-fit: fill;
}
.videoCenter .container .videoCenter-right .video-item .title[data-v-2573498e] {
  font-size: var(--f18);
  color: #333333;
  margin-top: 0.2rem;
}
.videoCenter .container .videoCenter-right .video-item:hover .title[data-v-2573498e] {
  color: var(--themeColor);
}