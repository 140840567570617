.detail .container[data-v-ff5c2ad4] {
  margin: 1.2rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.detail .container .detail-left[data-v-ff5c2ad4] {
  width: 100%;
  padding-right: 0.65rem;
  border-right: 0.01rem solid #ebebeb;
}
.detail .container .detail-left .swarticle .title[data-v-ff5c2ad4] {
  font-size: 0.36rem;
  color: #191c22;
  font-weight: bold;
}
.detail .container .detail-left .swarticle .author[data-v-ff5c2ad4] {
  font-size: var(--f18);
  color: #666666;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.detail .container .detail-left .swarticle .content[data-v-ff5c2ad4] {
  margin-bottom: 0.4rem;
}
.detail .container .detail-left .share[data-v-ff5c2ad4] {
  margin-top: 0.1rem;
}
.detail .container .detail-left .share p[data-v-ff5c2ad4] {
  font-size: var(--f14);
  color: #8c94a3;
  margin-bottom: 0.12rem;
}
.detail .container .detail-left .share .sharecontent[data-v-ff5c2ad4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.detail .container .detail-left .share .sharecontent .sharelist .sharelist-item[data-v-ff5c2ad4] {
  margin-right: 0.12rem;
}
.detail .container .detail-left .share .sharecontent .sharelist .sharelist-item img[data-v-ff5c2ad4] {
  width: 0.26rem;
  height: 0.26rem;
  padding: 0.12rem 0.12rem;
  border: 0.02rem solid #ebebeb;
  background-color: #ffffff;
}
.detail .container .detail-left .share .sharecontent .sharelist .sharelist-item:hover img[data-v-ff5c2ad4] {
  background-color: var(--themeColor);
}
.detail .container .detail-left .share .sharecontent .return[data-v-ff5c2ad4] {
  color: #999999;
  font-size: var(--f14);
  width: 1.04rem;
  height: 0.54rem;
  border: 0.02rem solid #EDEDED;
  text-align: center;
  line-height: 0.54rem;
  cursor: pointer;
}
.detail .container .detail-left .next[data-v-ff5c2ad4] {
  margin-top: 0.6rem;
}
.detail .container .detail-left .next p[data-v-ff5c2ad4] {
  font-size: var(--f16);
  color: #3f4150;
  padding: 0.25rem 0.3rem;
  background-color: #ededed;
  margin-bottom: 0.1rem;
}
.detail .container .detail-right[data-v-ff5c2ad4] {
  margin-left: 0.65rem;
}
.detail .container .detail-right h3[data-v-ff5c2ad4] {
  font-size: var(--f24);
  font-weight: bold;
  color: #000000;
}
.detail .container .detail-right .hotlist-item[data-v-ff5c2ad4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.3rem 0;
  border-bottom: 0.01rem solid #ebebeb;
  cursor: pointer;
}
.detail .container .detail-right .hotlist-item .pic[data-v-ff5c2ad4] {
  width: 2rem;
}
.detail .container .detail-right .hotlist-item-info[data-v-ff5c2ad4] {
  margin-left: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.detail .container .detail-right .hotlist-item-info .title[data-v-ff5c2ad4] {
  font-size: var(--f18);
  color: #000000;
}
.detail .container .detail-right .hotlist-item-info .time[data-v-ff5c2ad4] {
  margin-top: 0.4rem;
  font-size: var(--f16);
  color: #999999;
}